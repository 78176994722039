import { useEffect, useState } from "react";
import React, { lazy } from "react";
import { Outlet } from "react-router";
import Cookies from "js-cookie";
import MainSideMenu from "../Menu/SideMenu/MainSideMenu";
import NotificationMenu from "../Menu/NotificationMenu/NotificationMenu";
import {
  DashboardIcon,
  AdminBooking,
  AllBookings,
  AllRoom,
  MyBooking,
  UserManagementIcon
} from "../../utils/IconConstant";
const GetAllBookings = lazy(() => import("../../Pages/GetAllBookings/GetAllBookings"));

const MyBookings = lazy(() => import("../../Pages/MyBookings/MyBookings.jsx"));

const AvailableRooms = lazy(() => import("../../Pages/AvailableRooms/AvailableRooms"));

export const renderDashboardBasedOnRole = () => {
  // Logic to determine the user's roles and return the appropriate dashboard component
  // ...

  const rolesCookie = Cookies.get("roles");
  const rolesArray = rolesCookie ? JSON.parse(rolesCookie) : [];
  // const rolesArray = JSON.parse(Cookies.get("roles"));
  const userRoles = rolesArray; // Example roles (array of roles)

  console.log("printing roles data", rolesArray);

  // Map roles to their corresponding dashboard components
  const roleDashboardMap = {
    "Cowork Admin" : <AvailableRooms />,
    "Cowork Staff" : <AvailableRooms />,
    "Cowork Student" : <AvailableRooms />,
    "Cowork Faculty" : <AvailableRooms />,
    "Cowork UL Booking":<AvailableRooms />,
  };

  // Find the first matching role from the userRoles array
  const matchingRole = userRoles.find((role) =>
    roleDashboardMap.hasOwnProperty(role)
  );

  // Retrieve the dashboard component based on the matching role or default component
  const dashboardComponent =
    roleDashboardMap[matchingRole] || roleDashboardMap.default;

  // Return the dashboard component
  return dashboardComponent;
};

export const renderMenuItemsBasedOnRole = () => {
  // Replace this with your logic to determine the user's role

  const rolesCookie = Cookies.get("roles");
  const rolesArray = rolesCookie ? JSON.parse(rolesCookie) : [];
  // const rolesArray = JSON.parse(Cookies.get("roles"));
  const userRole = rolesArray; // Example roles (array of roles)

  const roleMenuItemsMap = {
    "Cowork Admin": [
      {
        label: "Admin Booking",
        path: "/",
        icon: <AdminBooking />,
      },
      {
        label: "My Bookings",
        path: "/mybookings",
        icon: <MyBooking />,
      },
      {
        label: "All Rooms",
        path: "/allrooms",
        icon: <AllRoom />,
      },
      {
        label: "All Bookings",
        path: "/allroombookings",
        icon: <AllBookings />,
      },
      {
        label: "User Control",
        path: "/userManagement",
        icon: <UserManagementIcon />,
      },
    ],
    "Cowork Faculty": [
      {
        label: "All Rooms",
        path: "/",
        icon: <AllRoom />,
      },
      {
        label: "My Bookings",
        path: "/mybookings",
        icon: <MyBooking />,
      },
    ],

    "Cowork Staff": [
      {
        label: "All Rooms",
        path: "/",
        icon: <AllRoom />,
      },
      {
        label: "My Bookings",
        path: "/mybookings",
        icon: <MyBooking />,
      },
    ],
    "Cowork UL Booking": [
      {
        label: "All Rooms",
        path: "/",
        icon: <AllRoom />,
      },
      {
        label: "My Bookings",
        path: "/mybookings",
        icon: <MyBooking />,
      },
    ],
    "Cowork Student": [
      {
        label: "All Rooms",
        path: "/",
        icon: <AllRoom />,
      },
      {
        label: "My Bookings",
        path: "/mybookings",
        icon: <MyBooking />,
      },
    ],
    default: [
      { label: "Dashboard", path: "/", icon: <DashboardIcon /> },
    ],
  };

  // Combine menu items for all roles
  const mergedMenuItems = userRole.reduce((mergedItems, role) => {
    const roleMenuItems = roleMenuItemsMap[role] || roleMenuItemsMap.default;
    return mergedItems.concat(roleMenuItems);
  }, []);

  // Remove duplicates from merged menu items
  const uniqueMenuItems = mergedMenuItems.filter(
    (menuItem, index, self) =>
      index === self.findIndex((m) => m.path === menuItem.path)
  );

  console.log("printing menu items", uniqueMenuItems);

  return uniqueMenuItems;
};

const MainLayout = () => {
  useEffect(() => {
    renderMenuItemsBasedOnRole();
  }, []);


  const [isActiveMenu, setIsActiveMenu] = useState(false)

  return (
    <div className={`appContainer`}>
      <MainSideMenu menuItems={renderMenuItemsBasedOnRole()} isActiveMenu={isActiveMenu} setIsActiveMenu={setIsActiveMenu} />
      <div className="workSpace">
        <NotificationMenu setIsActiveMenu={setIsActiveMenu} isActiveMenu={isActiveMenu}/>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;